import Alpine from 'alpinejs'
import swipePlugin from "alpinejs-swipe";
import intersect from "@alpinejs/intersect";
Alpine.plugin(swipePlugin);
Alpine.plugin(intersect);
window.Alpine = Alpine

// console.log('running from app.js')
// Alpine.start();

window.LargeCardSliderHandler = function (largeCardSliderSectionId) {
	return {
		lastActiveSelector: 0,
		allSelectors: document.querySelectorAll(`#${largeCardSliderSectionId} .sliderSelector`),
		allCards: document.querySelectorAll(`#${largeCardSliderSectionId} .sliderCard`),
		allTopSelectors: document.querySelectorAll(`#${largeCardSliderSectionId} .sliderSelectorTop`),
		init() {
			this.allSelectors.forEach((button, index) => {
				button.addEventListener('click', () => {
					this.allCards.forEach(item => {
						item.classList.add('inactiveSlide');
					});
					this.allCards[index].classList.remove('inactiveSlide');
					
					// important to do updateTopSelectors first because updateSelectors changes lastActiveSelector variable!
					this.updateTopSelectors(index);
					this.updateSelectors(index);
				});
			});
			
			this.allTopSelectors.forEach((button, index) => {
				button.addEventListener('click', () => {
					this.allCards.forEach(item => {
						item.classList.add('inactiveSlide');
					});
					this.allCards[index].classList.remove('inactiveSlide');
					
					this.updateTopSelectors(index);
					this.updateSelectors(index);
				});
			});
		},
		swipedRightHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === 0) {
				nextActiveSelector = this.allSelectors.length - 1;
			} else {
				nextActiveSelector = this.lastActiveSelector - 1;
			}
			
			this.allCards.forEach(item => {
				item.classList.add('inactiveSlide');
			});
			this.allCards[nextActiveSelector].classList.remove('inactiveSlide');
			
			this.updateTopSelectors(nextActiveSelector);
			this.updateSelectors(nextActiveSelector);
		},
		
		swipedLeftHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === this.allSelectors.length - 1) {
				nextActiveSelector = 0;
			} else {
				nextActiveSelector = this.lastActiveSelector + 1;
			}
			
			this.allCards.forEach(item => {
				item.classList.add('inactiveSlide');
			});
			this.allCards[nextActiveSelector].classList.remove('inactiveSlide');
			
			this.updateTopSelectors(nextActiveSelector);
			this.updateSelectors(nextActiveSelector);
		},
		
		updateSelectors(number) {
			this.allSelectors[this.lastActiveSelector].classList.remove('bg-black');
			this.allSelectors[this.lastActiveSelector].classList.add('bg-yanceygrey');
			this.allSelectors[number].classList.add('bg-black');
			this.allSelectors[number].classList.remove('bg-yanceygrey');
			this.lastActiveSelector = number;
		},
		
		updateTopSelectors(number) {
			this.allTopSelectors[this.lastActiveSelector].classList.remove('border-b-2');
			this.allTopSelectors[this.lastActiveSelector].classList.remove('border-yanceyyellow');
			this.allTopSelectors[number].classList.add('border-b-2');
			this.allTopSelectors[number].classList.add('border-yanceyyellow');
		},
	};
}

window.SmallCardSliderHandler = function (cardSliderSectionId) {
	return {
		lastActiveSelector: 0,
		allSelectors: document.querySelectorAll(`#${cardSliderSectionId} .sliderSelector`),
        allMobileSelectors: document.querySelectorAll(`#${cardSliderSectionId} .sliderSelectorMobile`),
		allCards: document.querySelectorAll(`#${cardSliderSectionId} .sliderCard`),
        chunkedCards: [],
        lastActiveChunk: 0,
		init() {
			
			// chunks all Cards into groups of 3
            const allCardsArray = [...this.allCards];
            for (let i=0; i < allCardsArray.length; i += 3) {
				const chunk = allCardsArray.slice(i, i+3);
                this.chunkedCards.push(chunk);
				
            }
			
            // Desktop slider selectors
            this.allSelectors.forEach((button, index)=>{
				button.addEventListener('click', () => {
					
					// remove visibility from previous cards
                    for (let i=0; i < this.chunkedCards[this.lastActiveChunk].length; i++) {
						this.chunkedCards[this.lastActiveChunk][i].classList.remove('activeDesktop')
                    }
                    // add visibility to display new cards
                    for (let i=0; i < this.chunkedCards[index].length; i++) {
						this.chunkedCards[index][i].classList.add('activeDesktop')
                    }
					
                    // remove visibility from last mobile card
                    this.allCards[this.lastActiveSelector].classList.add('inactiveSlide');
                    // add mobile visibility to the first of the set of 3 new cards so that a card is available to display if screen is resized to mobile
                    this.allCards[index*3].classList.remove('inactiveSlide');
					
                    // update the selected Selector color and update the values for lastActiveSelector and lastActiveChunk
                    this.updateSelectors(index*3, index);
                    
                })
            })
			
			this.allMobileSelectors.forEach((button, index) => {
				
				button.addEventListener('click', () => {
					
					// remove visisbility for all mobile cards
					this.allCards.forEach(item => {
						item.classList.add('inactiveSlide');
					});
                    
                    // add visibility for the mobile card at the selectors index
					this.allCards[index].classList.remove('inactiveSlide');
					
                    let chunkIndex //to be passed through updateSelectors
                    if (index === 0) {
						chunkIndex = 0;
                    } else {
						chunkIndex = Math.floor(index / 3);
                    }
					
                    // remove visibility from previous cards
                    for (let i=0; i < this.chunkedCards[this.lastActiveChunk].length; i++) {
						this.chunkedCards[this.lastActiveChunk][i].classList.remove('activeDesktop')
                    }
                    // add visibility to display new cards
                    for (let i=0; i < this.chunkedCards[chunkIndex].length; i++) {
						this.chunkedCards[chunkIndex][i].classList.add('activeDesktop')
                    }
					
					this.updateSelectors(index, chunkIndex);
				});
			});
		},
		swipedRightHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === 0) {
				nextActiveSelector = this.allMobileSelectors.length - 1;
			} else {
				nextActiveSelector = this.lastActiveSelector - 1;
			}
			
			this.allCards.forEach(item => {
				item.classList.add('inactiveSlide');
			});
			this.allCards[nextActiveSelector].classList.remove('inactiveSlide');
			
            let chunkIndex //to be passed through updateSelectors
			if (nextActiveSelector === 0) {
				chunkIndex = 0;
			} else {
				chunkIndex = Math.floor(nextActiveSelector / 3);
			}
			
			// remove visibility from previous cards
			for (let i=0; i < this.chunkedCards[this.lastActiveChunk].length; i++) {
				this.chunkedCards[this.lastActiveChunk][i].classList.remove('activeDesktop')
			}
			// add visibility to display new cards
			for (let i=0; i < this.chunkedCards[chunkIndex].length; i++) {
				this.chunkedCards[chunkIndex][i].classList.add('activeDesktop')
			}
			
			this.updateSelectors(nextActiveSelector, chunkIndex);
		},
		
		swipedLeftHandler() {
			let nextActiveSelector;
			if (this.lastActiveSelector === this.allMobileSelectors.length - 1) {
				nextActiveSelector = 0;
			} else {
				nextActiveSelector = this.lastActiveSelector + 1;
			}
			
			this.allCards.forEach(item => {
				item.classList.add('inactiveSlide');
			});
			this.allCards[nextActiveSelector].classList.remove('inactiveSlide');
			
            let chunkIndex //to be passed through updateSelectors
            if (nextActiveSelector === 0) {
				chunkIndex = 0;
            } else {
				chunkIndex = Math.floor(nextActiveSelector / 3);
            }
			
            // remove visibility from previous cards
            for (let i=0; i < this.chunkedCards[this.lastActiveChunk].length; i++) {
				this.chunkedCards[this.lastActiveChunk][i].classList.remove('activeDesktop')
            }
            // add visibility to display new cards
            for (let i=0; i < this.chunkedCards[chunkIndex].length; i++) {
				this.chunkedCards[chunkIndex][i].classList.add('activeDesktop')
            }
			
			this.updateSelectors(nextActiveSelector, chunkIndex);
		},
		
		updateSelectors(mobileIndex, chunkIndex) {
			// change the color of the previous and current active mobile selectors
			this.allMobileSelectors[this.lastActiveSelector].classList.remove('bg-black');
			this.allMobileSelectors[this.lastActiveSelector].classList.add('bg-yanceygrey');
			this.allMobileSelectors[mobileIndex].classList.add('bg-black');
			this.allMobileSelectors[mobileIndex].classList.remove('bg-yanceygrey');
			
            // change the color of the previous and current active desktop selectors
            this.allSelectors[this.lastActiveChunk].classList.remove('bg-black');
            this.allSelectors[this.lastActiveChunk].classList.add('bg-yanceygrey');
            this.allSelectors[chunkIndex].classList.add('bg-black');
            this.allSelectors[chunkIndex].classList.remove('bg-yanceygrey');
			
            // change the values for lastActiveSelector and lastActiveChunk
			this.lastActiveSelector = mobileIndex;
            this.lastActiveChunk = chunkIndex;
		},
    }
}
